import { getTillSettings } from "../api/graphql/tillSettings";
import { findTillSettingValue } from "../helpers/settingHelpers";
import { CartObject } from "../types/pos";
import { PaymentTerminalSettings, TillSetting } from "../types/settings";
import { PaymentProvider } from "../types/paymentTerminal";

type PaymentModule = {
  // TODO:: Improve types
  config: any;
  processSale: Function;
  processReturn: Function;
  voidSale: Function;
  openMenu: (settings: PaymentTerminalSettings) => void;
  configure: (
    settings: TillSetting[],
    tillId: number
  ) => PaymentTerminalSettings;
  cancelRequest: (settings: PaymentTerminalSettings) => Promise<any>;
};

type PaymentProviderConfig = {
  forceFinalizeTimeout?: number;
  transactionTimeout?: number;
};

class PaymentTerminal {
  tillId: number;
  tillSettings: TillSetting[] = [];
  paymentProvider: PaymentProvider;
  paymentModule: PaymentModule;
  settings: PaymentTerminalSettings;
  config: PaymentProviderConfig = {};

  async changeTill(tillId: number | string | undefined) {
    if (Number(tillId) > 0) {
      this.tillId = Number(tillId);
      return this.loadPaymentProviderSettings();
    }
    return Promise.resolve();
  }

  async loadPaymentProviderSettings() {
    this.tillSettings = await getTillSettings(this.tillId);
    const provider = this.determinePaymentProvider();
    if (provider) {
      return this.loadPaymentProvider(provider);
    }
  }

  determinePaymentProvider(): PaymentProvider {
    const provider = findTillSettingValue(this.tillSettings, "paymentProvider");
    if (["PAX", "USAePay", "mxMerchant"].includes(provider)) {
      this.paymentProvider = provider as PaymentProvider;
      return provider as PaymentProvider;
    }
    // Legacy settings
    const paxEnabled = findTillSettingValue(this.tillSettings, "paxEnabled");
    if (paxEnabled) {
      return "PAX";
    }
    return null;
  }

  async loadPaymentProvider(provider: PaymentProvider) {
    if (provider === "USAePay") {
      console.log("Loading USAePay...");
      const module = await import("./paymentProviders/usaepay");
      this.paymentModule = module;
      this.config = module.config;
      this.settings = this.paymentModule.configure(
        this.tillSettings,
        this.tillId
      );
    }
    if (provider === "mxMerchant") {
      console.log("Loading MX Merchant...");
      const module = await import("./paymentProviders/mxmerchant");
      this.paymentModule = module;
      this.settings = this.paymentModule.configure(
        this.tillSettings,
        this.tillId
      );
    }
    if (provider === "PAX") {
      const module = await import("./paymentProviders/pax");
      this.paymentModule = module;
      this.settings = this.paymentModule.configure(
        this.tillSettings,
        this.tillId
      );
    }
  }

  async processSale(amount: number, cart?: CartObject) {
    console.log(`process sale: ${amount}`);
    if (!this.paymentModule.processSale) {
      throw new Error("Not implemented");
    }
    return this.paymentModule.processSale(amount, this.settings, cart);
  }

  async processReturn(
    amount: number,
    cart: CartObject,
    originalTransactionNumber: string,
    tillName?: string,
    customerEmail?: string
  ) {
    if (!this.paymentModule.processReturn) {
      throw new Error("Not implemented");
    }
    return this.paymentModule.processReturn(
      amount,
      this.settings,
      cart,
      originalTransactionNumber,
      tillName,
      customerEmail
    );
  }

  async voidSale(
    transactionNumber: number,
    amount?: number,
    referenceNumber = 1
  ) {
    if (!this.paymentModule.voidSale) {
      throw new Error("Not implemented");
    }
    return this.paymentModule.voidSale(
      transactionNumber,
      amount,
      referenceNumber,
      this.settings
    );
  }

  async cancelCurrentTransaction() {
    if (!this.paymentModule.cancelRequest) {
      throw new Error("Not implemented");
    }
    return this.paymentModule.cancelRequest(this.settings);
  }

  async openMenu() {
    if (this.paymentModule.openMenu) {
      return this.paymentModule.openMenu(this.settings);
    }
    return Promise.resolve();
  }
}

export default PaymentTerminal;
