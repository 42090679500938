import { useState, useRef, useEffect } from "react";
import { Field, Form, Formik } from "formik";
import { PRICING_TYPE_OPTIONS } from "../../constants/settings";
import { notify } from "../../helpers/notificationHelpers";
import { convertMultiplierToPercentage } from "../../helpers/pricingHelpers";
import { useUpdateGamePriceRule } from "../../hooks/pricingHooks";
import { CURRENCY_OVERRIDE_WORDING } from "../../constants/featureFlags";
import { useFeatureFlag } from "../../hooks/globalSettingsHooks";
import { useShowError } from "../../hooks/errorHooks";
import { segmentAnalytics } from "../services/Analytics";
import { SAVE_GAME_PRICE_SETTINGS_CLICKED } from "../../constants/eventsTracked";
import { priceRuleSchema } from "../../schemas/pricing";
import { GamePriceRule, GamePricingTypeOption } from "../../types/settings";
import SettingsSection from "./SettingsSection";
import Input from "../form/Input";
import Select from "../form/Select";
import NavigationPromptWatcher from "../nav/NavigationPromptWatcher";
import GamePriceFloors from "./GamePriceFloors";
import PriceRounding from "./PriceRounding";
import "./GamePriceRules.scss";

const extendPricingTypeOptions = (
  suppliedOption: string,
  options: GamePricingTypeOption[]
) => {
  if (options.find((option) => option.value === suppliedOption)) {
    return options;
  }
  return [
    {
      value: suppliedOption,
      description: suppliedOption,
    },
    ...options,
  ];
};

interface GamePriceRulesProps {
  priceRule: GamePriceRule & { exists: boolean };
}

const GamePriceRules = ({ priceRule }: GamePriceRulesProps) => {
  const { gameName, game, setName, tcgPlayerPriceType } = priceRule || {};
  const isCurrencyOverrideFlagEnabled = useFeatureFlag(
    CURRENCY_OVERRIDE_WORDING
  );

  const formikRef = useRef<any>();
  const [initialData, setInitialData] = useState(
    convertMultiplierToPercentage(priceRule, ["priceMarkup", "priceMultiplier"])
  );

  const [isEditable, setIsEditable] = useState(false);
  const [priceRulesExist, setPriceRulesExist] = useState(priceRule?.exists);

  const { mutateAsync, isMutating, error } = useUpdateGamePriceRule();
  const showError = useShowError();

  useEffect(() => {
    if (error) {
      showError(
        error as DetailedError,
        "Failed to save",
        "Unable to save your price settings. Please try again"
      );
    }
  }, [error]);

  const handleToggleEdit = (value) => {
    setIsEditable(value);
    if (value === false) {
      // This resets the Formik form with the default values
      if (formikRef && formikRef.current) {
        formikRef.current.resetForm();
      }
    }
  };

  const handleOnSubmit = (value: GamePriceRule & { exists: boolean }) => {
    const {
      noRounding,
      priceMarkup,
      priceMultiplier,
      updatePricing,
      roundToNearest,
      useTCGPlayerPricing,
      tcgPlayerPriceType,
    }: GamePriceRule = value;

    segmentAnalytics.track(SAVE_GAME_PRICE_SETTINGS_CLICKED, {
      product_type: gameName,
      price_markup: priceMarkup !== 0 ? Number(priceMarkup) : "",
      price_multiplier: priceMultiplier !== "" ? Number(priceMultiplier) : "",
      price_update_enabled: Boolean(updatePricing),
      price_rounding:
        Number(roundToNearest) !== 0
          ? Number(roundToNearest).toFixed(2)
          : "No Game-Specific Rounding",
      pricing_type: tcgPlayerPriceType,
    });

    mutateAsync(
      {
        game,
        noRounding: noRounding === "true",
        priceMarkup: Number(priceMarkup) / 100,
        priceMultiplier:
          priceMultiplier !== "" ? Number(priceMultiplier) / 100 : null,
        roundToNearest: roundToNearest !== null ? Number(roundToNearest) : null,
        setName,
        tcgPlayerPriceType,
        updatePricing: updatePricing === "true",
        useTCGPlayerPricing: useTCGPlayerPricing === "true",
      },
      {
        onSuccess: () => {
          notify.info(
            "Your pricing adjustments have been queued and will take effect in about 12 hours."
          );
          setInitialData(value);
          setIsEditable(false);
          setPriceRulesExist(true);
        },
      }
    ).catch(() => {
      handleToggleEdit(false);
    });
  };

  const isEditing = isEditable && !isMutating;

  if (priceRule === null) {
    return <>No price rule provided.</>;
  }

  return (
    <Formik
      onSubmit={handleOnSubmit}
      initialValues={initialData}
      enableReinitialize
      innerRef={formikRef}
      validationSchema={priceRuleSchema}
    >
      {({ errors, dirty, submitForm }) => (
        <SettingsSection
          title={gameName}
          subtitle="Basic Settings"
          isEditing={isEditable}
          isMutating={isMutating}
          enableEditMode
          toggleEditMode={handleToggleEdit}
          toggleTextOn="Edit Singles Settings"
          submitHandler={submitForm}
          formHasErrors={Object.keys(errors).length > 0}
        >
          <NavigationPromptWatcher
            dirty={dirty}
            formName={`game-price-rules-${gameName}`}
          >
            <Form className="GamePriceRules__form">
              <div>
                <Field
                  component={Select}
                  editing={isEditing}
                  label={"Price Updates Enabled"}
                  id={`updatePricing-${gameName}`}
                  name="updatePricing"
                  type="text"
                  required
                  placeholder={"Price Updates Enabled"}
                  tooltipContent={
                    <aside>
                      <p>
                        If enabled, the master price updater will run and update
                        prices automatically twice daily. If disabled, price
                        updates will not run and will be the responsibility of
                        the store.
                      </p>
                    </aside>
                  }
                >
                  <option value="true">Enable</option>
                  <option value="false">Disable</option>
                </Field>
              </div>
              <div>
                <Field
                  component={Select}
                  editing={isEditing}
                  label={"Pricing Type"}
                  id={`tcgPlayerPriceType-${gameName}`}
                  name="tcgPlayerPriceType"
                  type="text"
                  required
                  requiredLabel
                  placeholder={"Pricing Type"}
                  error={errors["tcgPlayerPriceType"] ?? undefined}
                  tooltip={
                    <>
                      The price point that your store is pulling automatic
                      pricing from.
                      <br />
                      Learn about each price type option in the{" "}
                      <a
                        href="https://binderpos.freshdesk.com/a/solutions/articles/43000669364#Pricing-Type"
                        target="_blank"
                      >
                        Price Settings help file
                      </a>
                      .
                    </>
                  }
                >
                  {extendPricingTypeOptions(
                    tcgPlayerPriceType,
                    PRICING_TYPE_OPTIONS
                  ).map((priceType) => (
                    <option value={priceType.value} key={priceType.value}>
                      {priceType.description}
                    </option>
                  ))}
                </Field>
              </div>
              <div>
                <Field
                  component={Input}
                  editing={isEditing}
                  label={
                    isCurrencyOverrideFlagEnabled
                      ? "Currency Override"
                      : "Price Multiplier"
                  }
                  id={`priceMultiplier-${gameName}`}
                  name="priceMultiplier"
                  type="text"
                  placeholder="E.g. 130%"
                  optionalLabel
                  tooltipContent={
                    isCurrencyOverrideFlagEnabled
                      ? "The Currency Override overrides the standard conversion rate for stores using a currency other than USD."
                      : "The price multiplier overrides the standard conversion rate for stores using a currency other than USD."
                  }
                  adornment="%"
                  helperText="% multiplier to override currency conversion"
                />
              </div>
              <div>
                <Field
                  component={Input}
                  editing={isEditing}
                  label="Price Markup or Markdown"
                  id={`priceMarkup-${gameName}`}
                  name="priceMarkup"
                  type="text"
                  placeholder="E.g., 50% or -50%"
                  optionalLabel
                  tooltipContent={
                    <aside>
                      <p>
                        <strong>
                          The percentage you want to mark up or mark down the
                          Price Type selected.
                        </strong>{" "}
                        For example, if the price type value were $1, a markup
                        of 50% would result in a price of $1.50, whereas a
                        markdown of -50% would result in a price of $0.50.
                      </p>
                    </aside>
                  }
                  helperText="Multiplier for pricing type"
                  adornment="%"
                />
              </div>
              <hr />
              <div>
                <PriceRounding isEditing={isEditing} game={game} />
              </div>
            </Form>
          </NavigationPromptWatcher>
          <GamePriceFloors
            priceRule={priceRule}
            priceRulesExist={priceRulesExist}
          />
        </SettingsSection>
      )}
    </Formik>
  );
};

export default GamePriceRules;
