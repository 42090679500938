import { SUPPORT_FRESHDESK_URL } from "../constants/support";
import {
  AdaCustomerDetails,
  handleHelpbotLaunch,
  handleReportABugLaunch,
} from "../helpers/adaBotHelpers";
import { RootMenuItem } from "../types/nav";

export const legacySupportMenuStructure = {
  screenSetting: null,
  title: "Support",
  iconClass: "fas fa-user-headset",
  menuStructure: null,
  subMenu: null,
};

export const getSupportMenuStructure = (
  customerDetails: AdaCustomerDetails
): RootMenuItem => ({
  title: "Support",
  target: "/Support",
  iconClass: "fas fa-question-circle",
  menuStructure: undefined,
  subMenu: {
    title: "Support",
    links: [
      {
        title: "Help Files",
        href: SUPPORT_FRESHDESK_URL,
        external: true,
      },
      {
        title: "Helpbot",
        href: null,
        clickHandler: () => handleHelpbotLaunch(customerDetails),
      },
      {
        title: "Report Bug",
        href: null,
        clickHandler: () => handleReportABugLaunch(customerDetails),
      },
    ],
  },
});
